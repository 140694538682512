import { error, IError } from './error'
import { notificación } from './utilidades'
import { RefCuenta } from './cuenta'
import firebase from "firebase"

export interface IResumenDeDatos {
	eventos: {
		cantidadTotal: {
			UTC: number
		}
	}
}

export const resumenDeDatos = {
	obtenerMesActual: function(): Promise<IResumenDeDatos> {
		let ahora = new Date()
		return this.obtenerMes(ahora.getFullYear(), ahora.getMonth()+1)
	},
	obtenerMesPasado: function(): Promise<IResumenDeDatos> {
		let ahora = new Date()
		let aaaa = ahora.getFullYear()
		let mm = ahora.getMonth()+1
		if (mm === 1) {
			aaaa--
			mm = 12
		}

		return this.obtenerMes(aaaa, mm)
	},
	obtenerAño: async function(año: number): Promise<IResumenDeDatos> {
		return this.obtener(año.toString())
	},
	obtenerMes: async function(año: number, mes: number): Promise<IResumenDeDatos> {
		return this.obtener(`${año}-${mes}`)
	},
	obtener: async function(doc: string): Promise<IResumenDeDatos> {
		let docResumenDeDatos: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>

		try {
			docResumenDeDatos = await RefCuenta.collection('resumenDeDatos').doc(doc).get()
		} catch (e) {
			return rechazar(error.n('[4023] Ocurrió un error.', e))
		}

		return construirDatos(docResumenDeDatos)
	}
}

function construirDatos(doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>): Promise<IResumenDeDatos> {
	let datos: IResumenDeDatos = {
		eventos: {
			cantidadTotal: {
				UTC: 0
			}
		}
	}

	let datosResumenDeDatos = doc.data()
	if (!datosResumenDeDatos || !datosResumenDeDatos.exists) {
		console.warn('resumenDeDatos: no se encontraron datos.')
		return Promise.resolve(datos)
	}

	if (!datosResumenDeDatos.eventos || !datosResumenDeDatos.eventos.cantidadTotal) {
		return rechazar(error.n('[4024] Ocurrió un error.'))
	}

	if (typeof datosResumenDeDatos.eventos.cantidadTotal.UTC !== 'number' || datosResumenDeDatos.eventos.cantidadTotal.UTC < 0) {
		return rechazar(error.n('[4025] Ocurrió un error.'))
	}

	datos.eventos.cantidadTotal.UTC = datosResumenDeDatos.eventos.cantidadTotal.UTC

	return Promise.resolve(datos)
}

let rechazar = (e: IError) => {
	notificación.error(e.msj)
	return Promise.reject()
}