
import EventosRecientesTotales from '@/components/EventosRecientesTotales.vue'
import EventosRecientesAutenticacion from '@/components/EventosRecientesAutenticacion.vue'
import EventosRecientesFiabilidad from '@/components/EventosRecientesFiabilidad.vue'
import EventosEstadisticasGenerales from '@/components/EventosEstadisticasGenerales.vue'
import { defineComponent } from 'vue'

export default defineComponent({
	components: {
		EventosRecientesTotales,
		EventosRecientesAutenticacion,
		EventosRecientesFiabilidad,
		EventosEstadisticasGenerales
	},
	setup() {
	},
})
