<template>
	<div class="uk-child-width-1-2@s" uk-grid>
		<div>
			<EventosRecientesTotales></EventosRecientesTotales>
		</div>
		<div>
			<EventosRecientesAutenticacion></EventosRecientesAutenticacion>
		</div>
		<div>
			<EventosRecientesFiabilidad></EventosRecientesFiabilidad>
		</div>
		<div>
			<EventosEstadisticasGenerales></EventosEstadisticasGenerales>
		</div>
	</div>
</template>

<script lang="ts">
import EventosRecientesTotales from '@/components/EventosRecientesTotales.vue'
import EventosRecientesAutenticacion from '@/components/EventosRecientesAutenticacion.vue'
import EventosRecientesFiabilidad from '@/components/EventosRecientesFiabilidad.vue'
import EventosEstadisticasGenerales from '@/components/EventosEstadisticasGenerales.vue'
import { defineComponent } from 'vue'

export default defineComponent({
	components: {
		EventosRecientesTotales,
		EventosRecientesAutenticacion,
		EventosRecientesFiabilidad,
		EventosEstadisticasGenerales
	},
	setup() {
	},
})
</script>
