
import { defineComponent, reactive } from 'vue'
import { resumenDeDatos, IResumenDeDatos } from '@/resumenDeDatos'

export default defineComponent({
	setup() {

		let obj = function(): IResumenDeDatos {
			return {
				eventos: {
					cantidadTotal: {
						UTC: 0
					}
				}
			}
		}

		let datos = reactive({
			mesActual: obj(),
			mesPasado: obj()
		} as {
			mesActual: IResumenDeDatos,
			mesPasado: IResumenDeDatos
		})

		Promise.all([
			resumenDeDatos.obtenerMesActual(),
			resumenDeDatos.obtenerMesPasado(),
		]).then(res => {
			datos.mesActual = res[0]
			datos.mesPasado = res[1]
		})

		return datos
	}
})
