
import { defineComponent, getCurrentInstance, nextTick, onBeforeUnmount } from 'vue'
import { eventos } from '@/fuentes'

export default defineComponent({
	setup() {

		const app = getCurrentInstance()
		if (!app) {
			window.alert('Ocurrió un error. Comunique este código de error al administrador: 75304557.')
			return
		}

		let google = app.appContext.config.globalProperties.$window.google

		let generarGrafica = (datos: [string, number][]) => {
			let visualizacion = new google.visualization.DataTable()
			visualizacion.addColumn('string', 'Hora')
			visualizacion.addColumn('number', 'Eventos')
			visualizacion.addRows(datos)

			let opciones =  {
				hAxis: {
					title: 'Hora'
				},
				vAxis: {
					title: 'Eventos'
				}
			}

			let grafico = new google.visualization.LineChart(document.getElementById('grafica-eventos-recientes'))
			grafico.draw(visualizacion, opciones)
		}

		nextTick().then(() => {
			google.charts.load('current', {packages: ['corechart', 'line']})
			google.charts.setOnLoadCallback(() => {
				eventos.inicializarRecientesTotales(generarGrafica)
			})
		})

		onBeforeUnmount(() => {
			eventos.desconectarRecientesTotales()
		})

	},
})
