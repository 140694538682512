<template>
	<div class="uk-card uk-card-default uk-card-small">
		<div class="uk-card-header">
			<h3 class="uk-card-title uk-margin-remove-bottom">Autenticación de eventos recientes</h3>
		</div>
		<div class="uk-card-body">
			<div id="grafica-eventos-recientes-autenticacion"></div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, nextTick, onBeforeUnmount } from 'vue'
import { eventos } from '@/fuentes'

export default defineComponent({
	setup() {

		const app = getCurrentInstance()
		if (!app) {
			window.alert('Ocurrió un error. Comunique este código de error al administrador: 74021703.')
			return
		}

		let google = app.appContext.config.globalProperties.$window.google

		let generarGrafica = (datos: [string, number, number][]) => {
			let visualizacion = new google.visualization.DataTable()
			visualizacion.addColumn('string', 'Hora')
			visualizacion.addColumn('number', 'Autenticación requerida confirmada')
			visualizacion.addColumn('number', 'Autenticación requerida no confirmada')
			visualizacion.addRows(datos)

			let opciones =  {
				hAxis: {
					title: 'Hora'
				},
				vAxis: {
					title: 'Eventos'
				}
			}

			let grafico = new google.visualization.ColumnChart(document.getElementById('grafica-eventos-recientes-autenticacion'))
      grafico.draw(visualizacion, opciones)
		}

		nextTick().then(() => {
			google.charts.load('current', {'packages':['bar']})
			google.charts.setOnLoadCallback(() => {
				eventos.inicializarRecientesAutenticacion(generarGrafica)
			})
		})

		onBeforeUnmount(() => {
			eventos.desconectarRecientesAutenticacion()
		})

	},
})
</script>
