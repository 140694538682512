<template>
	<div class="uk-card uk-card-default uk-card-small">
		<div class="uk-card-header">
			<h3 class="uk-card-title uk-margin-remove-bottom">Estadísticas generales de eventos</h3>
		</div>
		<div class="uk-card-body">
			<table class="uk-table uk-table-divider uk-table-small">
				<tbody>
					<tr>
						<td>Total de eventos este mes:</td>
						<td>{{ Intl.NumberFormat('es').format(mesActual.eventos.cantidadTotal.UTC) }}</td>
					</tr>
					<tr>
						<td>Total de eventos el mes pasado:</td>
						<td>{{ Intl.NumberFormat('es').format(mesPasado.eventos.cantidadTotal.UTC) }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import { resumenDeDatos, IResumenDeDatos } from '@/resumenDeDatos'

export default defineComponent({
	setup() {

		let obj = function(): IResumenDeDatos {
			return {
				eventos: {
					cantidadTotal: {
						UTC: 0
					}
				}
			}
		}

		let datos = reactive({
			mesActual: obj(),
			mesPasado: obj()
		} as {
			mesActual: IResumenDeDatos,
			mesPasado: IResumenDeDatos
		})

		Promise.all([
			resumenDeDatos.obtenerMesActual(),
			resumenDeDatos.obtenerMesPasado(),
		]).then(res => {
			datos.mesActual = res[0]
			datos.mesPasado = res[1]
		})

		return datos
	}
})
</script>
